import ParentService from '@/service/ParentService.js';

export default class UserService extends ParentService {
   list() {
      const url = `${super.baseUrl}companies_users`;
      return super.customGet(url);
   }

   add(dto) {
      const url = `${super.baseUrl}user`;
      return super.customPost(url, dto);
   }

   update(id, dto) {
      const url = `${super.baseUrl}user/${id}`;
      return super.customPut(url, dto);
   }

   reset(dto) {
      const url = `${super.baseUrl}password/reset`;
      return super.customPost(url, dto);
   }

   delete(id) {
      const url = `${super.baseUrl}companies_users/${id}`;
      return super.customDelete(url);
   }
}
