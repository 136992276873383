import { render, staticRenderFns } from "./reset.vue?vue&type=template&id=19af252e&scoped=true&"
import script from "./reset.vue?vue&type=script&lang=js&"
export * from "./reset.vue?vue&type=script&lang=js&"
import style0 from "./reset.vue?vue&type=style&index=0&rel=stylesheet%2Fscss&lang=scss&"
import style1 from "./reset.vue?vue&type=style&index=1&id=19af252e&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19af252e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VApp,VBtn,VCard,VCardActions,VForm,VMain,VSnackbar,VSpacer,VTextField})
