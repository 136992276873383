<template>
   <v-app>
      <v-main class="ma-0">
         <div class="login-container d-flex align-center">
            <v-card class="glass mx-auto pa-5 text-center" width="500" max-width="500"  dark justify="space-around" >
               <v-form ref="form" @submit.prevent="resetPassword">
                  <img class="mblogo align-center" src="@/assets/logo_wide.png" />
                  <h4 class="text-center mt-3 mb-2">Reset your password</h4>
                  <v-spacer></v-spacer>
                  <v-text-field
                     v-model="password"
                     label="New Password"
                     append-icon="mdi-lock"
                     type="password"
                     
                     solo
                     light
                     autocomplete="new-password" id="new-password"
                  ></v-text-field>
                  
                  <v-text-field
                     v-model="password_confirmation"
                     label="Confirm Password"
                     append-icon="mdi-lock"
                     type="password"
                     
                     solo
                     autocomplete="new-password" id="new-password-confirm"
                     light
                  ></v-text-field>
                  <v-card-actions class="justify-end pa-0">
                     <v-spacer></v-spacer>
                     <v-btn
                        class="align-center"
                        :loading="loading"
                        color="primary"
                        type="submit"
                        x-large
                     >
                        Reset
                     </v-btn>
                  </v-card-actions>
               </v-form>
            </v-card>
         </div>
         <div class="credit">
            <div>
               photo by <a :href="photoInfoUrl" target="_blank">{{photoInfoName}} </a> on <a href="https://unsplash.com/?utm_source=metabim&utm_medium=referral"> Unsplash</a>
            </div>
         </div>
         <v-snackbar
            v-model="snackbar"
            :color="messageType"
            elevation="10"
            transition="slide-y-transition"
            timeout="3000"
            @input="resetSnackbar"
         >
            {{ message }}

         </v-snackbar>

      </v-main>
   </v-app>
</template>

<script>
import { createApi } from 'unsplash-js';
import nodeFetch from 'node-fetch';
import UserService from '@/service/UserService.js';
import Util from '@/utils/';

export default {
   data() {
      return {
          unsplash: createApi({
            accessKey: 'ugIYTA1RzIKC5AKJL0uNUd-AMAlEFikQP4if-P4I924',
            fetch: nodeFetch,
         }),
         photo: '',
         photoInfoUrl: '',
         photoInfoName: '',
         snackbar: false,
         loading: false,
         password: '',
         password_confirmation: '',
         token: '',
         email: '',
      };
   },
   computed: {
      message() {
         return this.$store.state.app.message;
      },
      messageType() {
         return this.$store.state.app.messageType;
      },
   },
   watch: {
      message(msg) {
         if (msg) {
            this.snackbar = true;
         }
      },
   },
   mounted() {
      this.token = this.$route.query.token;
      this.email = this.$route.query.email;

      this.unsplash.photos.getRandom({
         query: 'industrial factory refinery',
         orientation: 'landscape',
         collections: ['8k1VK2dtJ1Y', '6u5oWZxOVG8'],
      }).then((result) => {
         if (result.errors) {
            console.log('error', result.errors[0]);
         } else {
            this.photo = result.response;
            this.photoInfoUrl = this.photo.user.links.html+'?utm_source=metabim&utm_medium=referral';
            this.photoInfoName = this.photo.user.name;
            document.querySelector('#app > div > main').loading = 'lazy';
            document.querySelector('#app > div > main').style.backgroundImage = `url(${this.photo.urls.regular})`;
            document.querySelector('#app > div > main').style.backgroundSize = 'cover';
            document.querySelector('#app > div > main').style.backgroundPosition = 'center';
         }
      });
   },
   methods: {
      resetSnackbar() {
         this.$store.dispatch('alertUser', '');
      },
      resetPassword() {
         this.loading = true;
         const dto = {
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            token: this.token,
         };
         const userService = new UserService();
         userService.reset(dto).then((response) => {
            this.loading = false;
            console.log('response', response);
            if (!response.ok) {
               //this.$mixpanel.track('Password Reset', response);
               Util.sendEvent('Password Reset', response);
               Util.showMessage(response.data.message, 'error');
            } else {
               //this.$mixpanel.track('Password Reset', response);
               Util.sendEvent('Password Reset', response);
               Util.showMessage('Password reset successfully');
               this.$router.push({ name: 'login' });
            }
         })
            .catch((error) => {
               //this.$mixpanel.track('Password Reset', response);
               Util.sendEvent('Password Reset', 'Error', error);
               console.log('error', error);
               this.loading = false;
               Util.showMessage(error.response.data.message, 'error');
            });
      },
   },
};
</script>

<style rel="stylesheet/scss" lang="scss">
 .mblogo {
  padding-top:10px;
  width: 250px;
}
.login-container {
  width: 100%;
  height: 100%;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
}

a {
  color: #fff;
}
a:visited {
  color: #fff;
}
.forgotPassowrd {
  float: right;
  font-size: 85%;
}
.glass
{
   background-image: radial-gradient(circle at 50% 100%, #4accff66 0%, #00224433 50%), radial-gradient(circle at 50% 20%, rgba(0,34,68,0.9) 30%, rgba(0,34,68,0.6) 80%, rgba(0,34,68,0.4) 150%)  ;
   backdrop-filter: blur(10px) ;
   -webkit-backdrop-filter: blur(10px);
   border-radius: 15px;
   /*shadow*/
   box-shadow: 0 8px 32px 0 rgba(0,0,0, 0.8) !important;
   border: 1px solid rgba(0, 34, 68, 0.3);
}
.credit {
  position: absolute;
  background-image: linear-gradient(180deg, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.8) 100%);
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.5rem 0.5rem;
  margin: 0px;
  height: 100px;
  font-size: 12px;
  color: #ffffff99;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;

  a {
    color: #fffff99 !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

</style>
